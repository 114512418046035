<div
  role="region"
  aria-label="Prenotazioni e Contatti"
  class="background row head_Container"
  [style.background]="
    '
linear-gradient(rgba(0,136,204,.459),rgba(0,136,204,.418)),url(' +
    data.backgroundImage +
    ')'
  "
>
  <div class="col-md-6">
    <h3
      role="region"
      aria-label="Azienda Ospedaliera Universitaria"
      class="azienda-ospedaliera leftMargin"
    >
      {{ data.title | translate }}
    </h3>
    <h3
      role="region"
      aria-label="Università degli studi della Campania Luigi Vanvitelli"
      class="universita-degli-stu leftMargin"
    >
      {{ data.subtitle | translate }}
    </h3>
    <a
      [routerLink]="'/azienda'"
      *ngIf="data.seeMore"
      role="region"
      aria-label="Scopri di piú"
      class="btn btn-outline-primary leftMargin"
      >{{ "HOME_SUBVIEWS.FIND" | translate }}</a
    >
    <a
      *ngIf="data.isContacts"
      (click)="redirectTo('book-visit')"
      class="book-a-visit btn btn-outline-primary leftMargin"
    >
      {{ "HOME_SUBVIEWS.RESERVATION" | translate }}
    </a>
  </div>
  <div class="col-md-6 col2 space">
    <button
      *ngFor="let action of data.actions"
      type="button"
      class="btn btn-secondary"
      aria-expanded="false"
      [attr.aria-label]="action.text"
    >
      <img
        [src]="'../../../assets/' + action.iconName"
        class="icon icon-white position"
        alt="icon"
      />
      <ng-container *ngIf="action.addressLink && action.target === '_blank'">
        <a
          tabindex="-1"
          role="region"
          aria-label="Prenota una visita"
          class="p-link"
          [href]="action.addressLink"
          [target]="action.target"
          >{{ action.text | translate }}</a
        ></ng-container
      >
      <ng-container *ngIf="action.addressLink && action.target === '_self'">
        <a
          tabindex="-1"
          role="region"
          aria-label="Ritira la cartella clinica"
          class="p-link"
          [routerLink]="action.addressLink"
          [target]="action.target"
          >{{ action.text | translate }}</a
        ></ng-container
      >
      <ng-container *ngIf="action.addressLink && !action.target">
        <a tabindex="-1" class="p-link" [href]="action.addressLink">{{
          action.text
        }}</a></ng-container
      >
      <ng-container *ngIf="!action.addressLink">
        <p>{{ action.text }}</p>
      </ng-container>
    </button>
  </div>
</div>
