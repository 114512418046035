<app-page-head [data]="sharedData.Contacts"></app-page-head>
<div class="container-fluid book-container" id="book-visit">
  <p class="text1">{{ "CONTACTS_SUBVIEWS.BOOK" | translate }}</p>
  <p class="text2">{{ "CONTACTS_SUBVIEWS.CUP" | translate }}</p>
  <div style="margin: 0 2.5%">
    <div class="row">
      <div class="minicard card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <a href="https://www.google.com/maps?q=Via+Luigi+De+Crecchio,+20,+80138+Napoli+NA&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj_7PfL_OLyAhUHMewKHQ2EDPIQ_AUoAXoECAEQAw"
              target="_blank" class="title-with-icon">
              <svg-icon-sprite classes="icon icon-primary icon-lg" src="../../../assets/sprite.svg#it-map-marker">
              </svg-icon-sprite>
              <h5 class="card-title">
                {{ "CONTACTS_SUBVIEWS.CUP" | translate }}
              </h5>
            </a>
            <p class="card-subtitle" style="margin-top: 2.5%">
              {{ "CONTACTS_SUBVIEWS.ADDRESS" | translate }}
            </p>
            <p class="card-text">
              {{ "CONTACTS_SUBVIEWS.SPORTELLO" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="minicard card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.GREEN_NUMBER_TITLE" | translate }}
            </h5>
            <a class="phone_number" href="tel:800177780">
              {{ "CONTACTS_SUBVIEWS.GREEN_NUMBER" | translate }}</a>

            <!-- <p class="card-text mt-10">
              {{ "CONTACTS_SUBVIEWS.PHONE_TEXT_1" | translate }}
            </p> -->
            <p class="card-text mt-10">
              {{ "CONTACTS_SUBVIEWS.PHONE_TEXT_2" | translate }}
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.BLUE_NUMBER_TITLE" | translate }}
            </h5>
            <a class="phone_number" href="tel:08118408313">{{ "CONTACTS_SUBVIEWS.BLUE_NUMBER" | translate }}
            </a>

            <p class="card-text mt-10">
              {{ "CONTACTS_SUBVIEWS.MOBILE_TEXT_1" | translate }}
            </p>
            <p class="card-text">
              {{ "CONTACTS_SUBVIEWS.MOBILE_TEXT_2" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.ALPI_NUMBER" | translate }}
            </h5>
            <p class="card-subtitle">
              {{ "CONTACTS_SUBVIEWS.ALPI" | translate }}
            </p>
            <a class="phone_number" href="tel:08118408314">{{ "CONTACTS_SUBVIEWS.ALPI_NUMBER" | translate }}
            </a>
            <p class="card-text mt-10">
              {{ "CONTACTS_SUBVIEWS.MOBILE_TEXT_2" | translate }}
            </p>
          </div>
        </div>
      </div> -->
      <div class="minicard card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.CARD_TITLE" | translate }}
            </h5>
            <p class="card-subtitle">
              {{ "CONTACTS_SUBVIEWS.CARD_SUBTITLE" | translate }}
            </p>
            <br />
            <p class="card-text">
              {{ "CONTACTS_SUBVIEWS.CARD_TEXT" | translate }}
            </p>

            <a class="read-more" target="_blank"
              href="https://mypay.regione.campania.it/pa/changeEnte.html?enteToChange=AOUS_063">
              <span class="text">{{ "BUTTON.GO_TO_PAGE" | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="contacts-body" role="region" aria-label="Informazioni Utili">
  <p class="text1">{{ "CONTACTS_SUBVIEWS.USEFUL_INFO" | translate }}</p>
  <p class="text2">{{ "CONTACTS_SUBVIEWS.HOW_TO_REACH_US" | translate }}</p>
  <div class="wrapper">
    <div class="firstRow">
      <div class="card-wrapper card-space col-sm" *ngFor="let card of this.firstCardRow">
        <div style="font-size: 20px" class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ card.Title | translate }}
            </h5>
            <ul>
              <li *ngFor="let line of card.CardContent">
                <span [innerHTML]="line.Text | translate"></span>
                <ul>
                  <li [innerHTML]="info.Text | translate" *ngFor="let info of line.subInfo"></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card-wrapper card-space col-sm" *ngFor="let card of this.secondCardRow; index as i"
        [ngStyle]="{ 'height': i === 2 ? '50%' : 'auto' }">
        <div style="font-size: 20px" class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ card.Title | translate }}
            </h5>
            <ul>
              <li *ngFor="let line of card.CardContent">
                <span [innerHTML]="line.Text | translate"></span>
                <ul>
                  <li [innerHTML]="info.Text | translate" *ngFor="let info of line.subInfo"></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- <p class="text1">{{ "CONTACTS_SUBVIEWS.ADDRESSES" | translate }}</p>
  <p class="text2">{{ "CONTACTS_SUBVIEWS.CONTACT_US" | translate }}</p> -->
  <!-- <div class="container">
    <div class="row">
      <div class="card-wrapper card-space col-sm card-contacts">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.MAIL_TITLE_1" | translate }}
            </h5>
            <a
              href="mailto:Protocollo.policliniconapoli.it@pec.it"
              class="card-text mail"
              >{{ "CONTACTS_SUBVIEWS.MAIL_1" | translate }}</a
            >
            <a
              href="mailto:Protocollo@policliniconapoli.it"
              class="card-text mail"
              >{{ "CONTACTS_SUBVIEWS.MAIL_2" | translate }}</a
            >
          </div>
        </div>
      </div>
      <div class="card-wrapper card-space col-sm card-contacts">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.MAIL_TITLE_2" | translate }}
            </h5>
            <a href="mailto:policlinico.napoli@pec.it" class="card-text mail">{{
              "CONTACTS_SUBVIEWS.MAIL_3" | translate
            }}</a>
          </div>
        </div>
      </div> -->
  <!-- <div class="card-wrapper card-space col-sm">
        <div class="card card-bg">
          <div class="card-body">
            <h5 class="card-title">
              {{ "CONTACTS_SUBVIEWS.MAIL_TITLE_3" | translate }}
            </h5>
            <a
              href="mailto:aouluigivanvitelli@gmail.com"
              class="card-text mail"
              >{{ "CONTACTS_SUBVIEWS.MAIL_4" | translate }}</a
            >
            <a
              href="mailto:ufficiostampa@policliniconapoli.it"
              class="card-text mail"
              >{{ "CONTACTS_SUBVIEWS.MAIL_5" | translate }}</a
            >
          </div>
        </div>
      </div> -->
  <!-- </div> -->
  <!-- </div> -->
</div>